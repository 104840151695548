import { useState } from 'react';
import { DetectedLocationData } from '../detectedLocationType';
import { useAppDispatch } from '../../../app/hooks';
import { updateStatus } from '../detectedLocationApi';
import { message, Select } from 'antd';

const { Option } = Select;
type props = {
  rowData: DetectedLocationData;
};

function StatusSelect({ rowData }: props) {
  const [selectedStatus, setSelectedStatus] = useState(rowData.status ? rowData.status : undefined);
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text
    });
  };

  return (
    <>
      {contextHolder}
      <Select
        allowClear
        showSearch
        style={{ width: '100%' }}
        size="large"
        placeholder="Status"
        onChange={(e) => {
          setSelectedStatus(e);
          dispatch(updateStatus({ id: rowData.id, status: e })).then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              success('Status updated successfully');
            }
          });
        }}
        defaultValue={selectedStatus}>
        <Option value={1} style={{ padding: 0 }}>
          <div style={{ backgroundColor: '#f77c80', height: '20px', width: '100%' }} />
        </Option>
        <Option value={2} style={{ padding: 0 }}>
          <div style={{ backgroundColor: '#fbc02d', height: '20px', width: '100%' }} />
        </Option>
        <Option value={3} style={{ padding: 0 }}>
          <div style={{ backgroundColor: '#66bb6a', height: '20px', width: '100%' }} />
        </Option>
      </Select>
    </>
  );
}

export default StatusSelect;
