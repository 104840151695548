import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  selectDetectedLocation,
  selectDetectedLocationSearch,
  selectRoadNames,
  selectTotalDistance,
  resetDetectedLocationDetail,
  setSearchValue,
  selectRoute
} from '../../detectedLocationSlice';
import { generateSummary, generateSummarySelected, getDetectedLocation, getLatLng } from '../../detectedLocationApi';
import { DateTime } from 'luxon';
import { BsFillCloudUploadFill, BsSearch, BsCloudDownload, BsDatabaseFillDown } from 'react-icons/bs';
import OneMap from '../OneMap';
import DetectedLocationTable from '../DetectedLocationTable';
import SearchA from '../../../onemap/Components/InputSearchA';
import SearchB from '../../../onemap/Components/InputSearchB';
import { selectSelectedLocation, selectSelectedLocationB } from '../../../onemap/onemapSlice';
import PdfInformationFormModal from '../PdfInformationFormModal';
import { NavLink, useSearchParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TrendAnalysis from '../TrendAnalysis';
import { Select, DatePicker, Progress } from 'antd';
import dayjs from 'dayjs';
import type { GetProps } from 'antd';
import TimePickerStartTime from '../../../../components/Datepicker/TimePickerStartTime';
import TimePickerEndTime from '../../../../components/Datepicker/TimePickerEndTime';
import { selectDetectedLocationIds } from '../../../uploadFolder/uploadFolderSlice';
import { downloadDatasets, migrationDatasets, uploadListIds } from '../../../uploadFolder/uploadFolderApi';
import { selectUserProfile } from '../../../auth/authSlice';
import { BiTransfer } from 'react-icons/bi';
import { message } from 'antd';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;
const { Option } = Select;

function DetectedLocation({ props }: any) {
  const [fromDate, setFromDate] = useState<any>(props.fromD || DateTime.now().toFormat('dd/MM/yyyy'));
  const [toDate, setToDate] = useState<any>(props.toD || DateTime.now().toFormat('dd/MM/yyyy'));
  const [fromTime, setFromTime] = useState<string | undefined>(props.fromTime || undefined);
  const [toTime, setToTime] = useState<string | undefined>(props.toTime || undefined);
  const [isDetected, setIsDetected] = useState<boolean>(props.isDetected == undefined ? Boolean(true) : props.isDetected);
  const [triggerType, setTriggerType] = useState<string | undefined>(props.triggerType || undefined);
  const [latitude, setLatitude] = useState<number | undefined>(props.latitude || undefined);
  const [distance, setDistance] = useState<number | undefined>(60);
  const [longitude, setLongitude] = useState<number | undefined>(props.longitude || undefined);
  const [radius, setRadius] = useState<number | undefined>(props.radius || 50);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const selectedLocationB = useAppSelector(selectSelectedLocationB);
  const selectedRoadNames = useAppSelector(selectRoadNames);
  const selectedTotalDistance = useAppSelector(selectTotalDistance);
  const route = useAppSelector(selectRoute);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterBy, setFilterBy] = useState<string>('month');
  const [startDateTrend, setStartDateTrend] = useState<Date>(new Date(2021, 3, 27));
  const [endDate, setEndDate] = useState<Date>(new Date(2021, 4, 27));
  const [startMonth, setStartMonth] = useState<Date>(new Date(2021, 3, 26));
  const [endMonth, setEndMonth] = useState<Date>(new Date(2021, 3, 26));
  const [startYear, setStartYear] = useState<number>(2021);
  const [endYear, setEndYear] = useState<number>(2021);
  const [defectTypes, setDefectTypes] = useState<string[]>(JSON.parse(props.defectTypes || '[]'));
  const [labelDay, setLabelDay] = useState<string[]>([]);
  const [isVisualization, setIsVisualization] = useState<boolean>(false);
  const [roadName, setRoadName] = useState<string>(props.roadName || undefined);
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(selectDetectedLocation);
  const [page, setPage] = useState<number>(parseInt(props.page) || 1);
  const [perPage, setPerPage] = useState<number>(parseInt(props.perPage) || 10);
  const dateFormat = 'DD/MM/YYYY';
  const [processed, setProcessed] = useState(0);
  const [orderBy, setOrderBy] = useState<any>(props.orderBy || 'datetime');
  const [order, setOrder] = useState<any>(props.order || 'desc');
  const [status, setStatus] = useState<number>(parseInt(props.status) || 0);
  const [dragLocation, setDragLocation] = useState<any>([]);
  const [dragLocationB, setDragLocationB] = useState<any>([]);
  const selectedIds = useAppSelector(selectDetectedLocationIds);
  const profile = useAppSelector(selectUserProfile);
  const [messageApi, contextHolder] = message.useMessage();
  const [isIncludesPDF, setIsIncludesPDF] = useState<boolean>(false);

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Migration from hot storage to cold storage successfully!'
    });
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    const endOfToday = dayjs().endOf('day').valueOf();
    const startOfFromDate = dayjs(fromDate, 'DD/MM/YYYY').startOf('day').valueOf();

    return current && (current.valueOf() > endOfToday || current.valueOf() < startOfFromDate);
  };

  const convertDateTime = (date: string) => {
    const newFromDate = date.slice(6, 8) + '/' + date.slice(4, 6) + '/' + date.slice(0, 4);
    return newFromDate;
  };

  useEffect(() => {
    handleRouteCoverageVisualization(isVisualization);
  }, [isVisualization]);

  useEffect(() => {
    if (selectedLocation?.LONGITUDE) {
      setDragLocation([Number(selectedLocation.LATITUDE), Number(selectedLocation.LONGITUDE)]);
    } else {
      setDragLocation([]);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (selectedLocationB?.LONGITUDE) {
      setDragLocationB([Number(selectedLocationB.LATITUDE), Number(selectedLocationB.LONGITUDE)]);
    } else {
      setDragLocationB([]);
    }
  }, [selectedLocationB]);

  const fetchData = (perPage?: number, page?: number, orderBy?: string, order?: string) => {
    const from = fromDate.includes('/') ? fromDate.split('/') : fromDate;
    const end = toDate.includes('/') ? toDate.split('/') : toDate;

    dispatch(
      getDetectedLocation({
        page,
        perPage,
        fromDate: fromDate.includes('/') ? from[2] + from[1] + from[0] : fromDate,
        toDate: toDate.includes('/') ? end[2] + end[1] + end[0] : toDate,
        fromTime: fromTime?.includes(':') ? dayjs(fromTime, 'HH:mm:ss').format('HHmmssSSS') : undefined,
        toTime: toTime ? dayjs(toTime, 'HH:mm:ss').format('HHmmssSSS') : undefined,
        triggerType: triggerType == 'all' ? undefined : triggerType,
        latitude: dragLocation.length ? dragLocation[0] : latitude,
        longitude: dragLocation.length ? dragLocation[1] : longitude,
        latitudeB: dragLocationB.length ? dragLocationB[0] : latitude,
        longitudeB: dragLocationB.length ? dragLocationB[1] : longitude,
        radius,
        isCrackDetected: isDetected,
        order,
        orderBy,
        filterBy: filterBy,
        defectTypes: JSON.stringify(defectTypes),
        roadName: roadName,
        status: status,
        distance: distance
      })
    );

    const record: Record<string, string | string[]> = {
      perPage: perPage?.toString() || '10',
      page: page?.toString() || '1',
      orderBy: searchValue?.orderBy || '',
      order: searchValue?.order || 'desc',
      fromDate: fromDate.includes('/') ? from[2] + from[1] + from[0] : fromDate,
      toDate: toDate.includes('/') ? end[2] + end[1] + end[0] : toDate,
      fromTime: fromTime?.includes(':') ? dayjs(fromTime, 'HH:mm:ss').format('HHmmssSSS') : fromTime || '',
      toTime: toTime?.includes(':') ? dayjs(toTime, 'HH:mm:ss').format('HHmmssSSS') : toTime || '',
      triggerType: triggerType || '',
      latitude: dragLocation.length ? dragLocation[0] : latitude ? latitude.toString() : '',
      longitude: dragLocation.length ? dragLocation[1] : longitude ? longitude.toString() : '',
      latitudeB: dragLocationB.length ? dragLocationB[0] : latitude ? latitude.toString() : '',
      longitudeB: dragLocationB.length ? dragLocationB[1] : longitude ? longitude.toString() : '',
      radius: radius ? radius.toString() : '',
      isDetected: isDetected.toString() || '',
      selectFolder: '',
      defectTypes: JSON.stringify(defectTypes),
      roadName: roadName || '',
      filterBy: filterBy
    };
    status && (record.status = status.toString());

    setSearchParams(record);
    dispatch(resetDetectedLocationDetail());
  };
  const searchValue = useAppSelector(selectDetectedLocationSearch);

  const handleSearch = () => {
    // localStorage.setItem('zoom', '13');
    setPage(1);
    fetchData(perPage, 1, searchValue?.orderBy, searchValue?.order);
  };

  const handleChange = (value: any) => {
    setDefectTypes(value);
  };

  const handleChangeStatus = (value: any) => {
    setStatus(value);
  };

  const handleChangeRoad = (value: any) => {
    setRoadName(value);
  };

  const handleRouteCoverageVisualization = (isVisualization: boolean) => {
    dispatch(getLatLng({ isVisualization }));
  };

  const onChangeStartDate = (date: any, dateString: string) => {
    setFromDate(dateString);
  };

  const onChangeEndDate = (date: any, dateString: string) => {
    setToDate(dateString);
  };

  const handleProcessed = (data: any) => {
    setProcessed(data);
    return data;
  };

  const handleOnChangePage = (data: number) => {
    setPage(data);
    setSearchParams((searchParams) => {
      searchParams.set('page', data.toString());
      return searchParams;
    });
    // dispatch(setSearchValue({ page: data }));
  };

  const handleOnChangePerPage = (data: number) => {
    setPerPage(data);
    setSearchParams((searchParams) => {
      searchParams.set('perPage', data.toString());
      return searchParams;
    });
    // dispatch(setSearchValue({ perPage: data }));
  };

  const handleOnChangeOrder = (data: string) => {
    setOrder(data);
    setSearchParams((searchParams) => {
      searchParams.set('order', data);
      return searchParams;
    });
    // dispatch(setSearchValue({ order: data }));
  };

  const handleOnChangeOrderBy = (data: string) => {
    setOrderBy(data);
    setSearchParams((searchParams) => {
      searchParams.set('orderBy', data);
      return searchParams;
    });
    // dispatch(setSearchValue({ orderBy: data }));
  };

  const handleFetchDataPdf = () => {
    fetchData(perPage, page, searchValue?.orderBy, searchValue?.order);
  };

  const handleDownloadPDFs = async () => {
    const ids = selectedIds?.map((data: any) => data.folderName).splice(0, 30); // limit 30 pdfs
    if (ids?.length) {
      const hide = message.loading('Downloading PDFs...', 0);
      try {
        await dispatch(uploadListIds({ ids })).unwrap();
        message.success('PDFs downloaded successfully!');
      } catch (error) {
        message.error('Failed to download PDFs.');
      } finally {
        hide();
      }
    }
  };

  const handleDownloadDataset = async () => {
    const ids = selectedIds?.map((data: any) => data.folderName).splice(0, 30); // limit 30 datasets
    if (ids?.length) {
      const hide = message.loading('Downloading datasets...', 0);
      try {
        await dispatch(downloadDatasets({ ids })).unwrap();
        message.success('Datasets downloaded successfully!');
      } catch (error) {
        message.error('Failed to download datasets.');
      } finally {
        hide();
      }
    }
  };

  const handleMigrationDataset = async () => {
    const ids = selectedIds?.map((data: any) => data.folderName).splice(0, 30); // limit 30 datasets
    if (ids?.length) {
      const hide = message.loading('Migrating datasets...', 0);
      try {
        await dispatch(migrationDatasets({ ids })).unwrap();
        message.success('Datasets migrate successfully!');
      } catch (error) {
        message.error('Failed to migrate datasets.');
      } finally {
        hide();
      }
    }
  };

  const handleGenerateSummary = async () => {
    const ids = selectedIds?.map((data: any) => data.folderName).splice(0, 30);
    const hide = message.loading('Generating summary...', 0);
    if (ids?.length === 0) {
      try {
        const from = fromDate.includes('/') ? fromDate.split('/') : fromDate;
        const end = toDate.includes('/') ? toDate.split('/') : toDate;
        await dispatch(
          generateSummary({
            page,
            perPage,
            fromDate: fromDate.includes('/') ? from[2] + from[1] + from[0] : fromDate,
            toDate: toDate.includes('/') ? end[2] + end[1] + end[0] : toDate,
            fromTime: fromTime?.includes(':') ? dayjs(fromTime, 'HH:mm:ss').format('HHmmssSSS') : undefined,
            toTime: toTime ? dayjs(toTime, 'HH:mm:ss').format('HHmmssSSS') : undefined,
            triggerType: triggerType == 'all' ? undefined : triggerType,
            latitude: dragLocation.length ? dragLocation[0] : latitude,
            longitude: dragLocation.length ? dragLocation[1] : longitude,
            latitudeB: dragLocationB.length ? dragLocationB[0] : latitude,
            longitudeB: dragLocationB.length ? dragLocationB[1] : longitude,
            radius,
            isCrackDetected: isDetected,
            order,
            orderBy,
            filterBy: filterBy,
            defectTypes: JSON.stringify(defectTypes),
            roadName: roadName,
            status: status,
            distance: distance
          })
        );
        message.success('Summary generated successfully!');
      } catch (error) {
        message.error('Failed to generate summary.');
      } finally {
        hide();
      }
    } else if (ids?.length) {
      try {
        await dispatch(generateSummarySelected({ ids }));
        message.success('Summary generated successfully!');
      } catch (error) {
        message.error('Failed to generate summary.');
      } finally {
        hide();
      }
    }
  };

  return (
    <>
      {contextHolder}
      <Container fluid>
        <Row className="mt-3">
          <Col lg="2">
            <Row>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label className="form-label">Start date</Form.Label>
                  <DatePicker
                    onChange={onChangeStartDate}
                    defaultValue={dayjs(searchParams.get('fromDate') ? convertDateTime(searchParams.get('fromDate') || '') : fromDate, dateFormat)}
                    format={dateFormat}
                    disabledDate={disabledDate}
                  />
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label className="form-label">End date</Form.Label>
                  <DatePicker
                    onChange={onChangeEndDate}
                    defaultValue={dayjs(searchParams.get('toDate') ? convertDateTime(searchParams.get('toDate') || '') : toDate, dateFormat)}
                    format={dateFormat}
                    disabledDate={disabledEndDate}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg="2">
            <Row>
              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label className="form-label" style={{ marginBottom: 0 }}>
                    Start time
                  </Form.Label>
                  <TimePickerStartTime startTime={fromTime} setStartTime={setFromTime} defaultValue={dayjs(fromTime, 'HHmmssSSS').format('HH:mm')} />
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group className="mb-3">
                  <Form.Label className="form-label" style={{ marginBottom: 0 }}>
                    End time
                  </Form.Label>
                  <TimePickerEndTime
                    endTime={toTime}
                    setEndTime={setToTime}
                    defaultValue={dayjs(toTime, 'HHmmssSSS').format('HH:mm')}
                    minTime={fromTime}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col lg="1">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Trigger type</Form.Label>
              <Form.Select
                value={triggerType}
                onChange={(e) => {
                  setTriggerType(e.target.value);
                }}>
                <option value={'all'}>All (System and Manual)</option>
                <option value="SYSTEM">System</option>
                <option value="MANUAL">Manual</option>
              </Form.Select>
            </Form.Group>
          </Col>

          <Col lg="2">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Search Location A</Form.Label>
              <SearchA props={route}></SearchA>
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Search Location B</Form.Label>
              <SearchB props={route}></SearchB>
            </Form.Group>
          </Col>
          <Col lg="1">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Latitude</Form.Label>
              <Form.Control
                type="number"
                id="inputLatitude"
                min="0"
                // step={0.1}
                value={latitude}
                onChange={(e) => {
                  setLatitude(parseFloat(e.target.value));
                }}
                disabled={!!selectedLocation}
              />
            </Form.Group>
          </Col>
          <Col lg="1">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Longitude</Form.Label>
              <Form.Control
                type="number"
                id="inputLongitude"
                min="0"
                value={longitude}
                disabled={!!selectedLocation}
                // step={0.1}
                onChange={(e) => {
                  setLongitude(parseFloat(e.target.value));
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="1">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Radius (m)</Form.Label>
              <Form.Control
                type="number"
                id="inputRadius"
                min="0"
                step={1}
                value={radius}
                onChange={(e) => {
                  setRadius(parseFloat(e.target.value));
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Check
              onChange={() => {
                setIsDetected(!isDetected);
              }}
              value={'on'}
              type="switch"
              id="custom-switch"
              label="Positive Detection Only"
              checked={isDetected}
            />
            <Form.Check
              onChange={() => {
                setIsIncludesPDF(!isIncludesPDF);
              }}
              type="checkbox"
              id="custom-checkbox"
              label="Includes PDF Reports"
              checked={isIncludesPDF}
              style={{ marginTop: '10px' }}
            />
          </Col>
          <Col lg="1" className="form-select-searchable">
            <label style={{ marginBottom: '8px' }}>
              <span>Status</span>
            </label>
            <Select
              allowClear
              showSearch
              style={{ width: '100%' }}
              size="large"
              placeholder="Status"
              onChange={handleChangeStatus}
              defaultValue={searchParams.get('status') && parseInt(searchParams.get('status') || '0')}>
              <Option value={1} style={{ padding: 0 }}>
                <div style={{ backgroundColor: '#f77c80', height: '20px', width: '100%' }} />
              </Option>
              <Option value={2} style={{ padding: 0 }}>
                <div style={{ backgroundColor: '#fbc02d', height: '20px', width: '100%' }} />
              </Option>
              <Option value={3} style={{ padding: 0 }}>
                <div style={{ backgroundColor: '#66bb6a', height: '20px', width: '100%' }} />
              </Option>
            </Select>
          </Col>
          <Col lg="2" className="form-select-searchable">
            <Button
              style={{ marginTop: '33px' }}
              onClick={() => {
                handleGenerateSummary();
              }}>
              Generate Summary
            </Button>
          </Col>
          <Col lg="2" className="form-select-searchable">
            <Row>
              <Col lg="6">
                <label style={{ marginBottom: '8px' }}>
                  <span>Road</span>
                </label>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: '100%'
                  }}
                  size="large"
                  placeholder="Input a road name"
                  onChange={handleChangeRoad}
                  defaultValue={searchParams.get('roadName') || null}
                  options={
                    selectedRoadNames && selectedRoadNames.length ? selectedRoadNames.map((item: any) => ({ value: item.id, label: item.name })) : []
                  }
                  filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
                />
              </Col>
              <Col lg="6">
                <label style={{ marginBottom: '8px' }}>
                  <span>Defect types</span>
                </label>
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{
                    width: '100%'
                  }}
                  size="large"
                  placeholder="Defect types"
                  onChange={handleChange}
                  defaultValue={searchParams.get('defectTypes') ? JSON.parse(searchParams.get('defectTypes') || '') : []}
                  options={[
                    { value: 1, label: 'Footpath crack' },
                    { value: 2, label: 'Tactile tile crack' },
                    { value: 3, label: 'Tactile tile missing' },
                    { value: 4, label: 'Tile crack' },
                    { value: 5, label: 'Tile missing' },
                    { value: 6, label: 'Grating damaged' },
                    { value: 7, label: 'Grating missing' },
                    { value: 8, label: 'Footpath uneven' },
                    { value: 9, label: 'Grating uneven' }
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col lg="1">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Distance</Form.Label>
              <Form.Control
                type="number"
                id="inputRoad"
                min="0"
                step={1}
                value={distance}
                defaultValue={distance}
                onChange={(e) => {
                  setDistance(parseFloat(e.target.value));
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="1" style={{ marginTop: '33px', marginLeft: '180px' }}>
            <Button
              onClick={() => {
                handleSearch();
              }}>
              {loading ? (
                <>
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  loading...
                </>
              ) : (
                <>
                  <BsSearch /> Search
                </>
              )}
            </Button>
          </Col>
          <Col>
            <Form.Check
              onChange={() => {
                setIsVisualization(!isVisualization);
              }}
              value={'off'}
              type="switch"
              id="visualization-switch"
              label="Coverage Visualization"
              checked={isVisualization}
            />
            <div>Total length route coverage(km) with defects: {selectedTotalDistance ? selectedTotalDistance.toFixed(6) : 0}</div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={5}>
            <Container className="shadow p-3 mb-5 bg-body rounded">
              {processed > 0 ? <Progress percent={processed} size="small" /> : null}
              <Tabs defaultActiveKey="data" className="mb-3">
                <Tab eventKey="data" title="Data">
                  <Row>
                    <Col sm="12" className="d-flex justify-content-between">
                      <span className="fs-4">Filter result</span>
                      {selectedIds && selectedIds.length > 0 ? (
                        <Button onClick={handleDownloadPDFs} size="sm">
                          <BsCloudDownload /> Download PDFs
                        </Button>
                      ) : null}
                      {selectedIds && selectedIds.length > 0 && profile?.type === 2 ? (
                        <>
                          <Button onClick={handleDownloadDataset} size="sm">
                            <BsDatabaseFillDown></BsDatabaseFillDown> Download Datasets
                          </Button>
                          <Button onClick={handleMigrationDataset} size="sm">
                            <BiTransfer></BiTransfer> Migration
                          </Button>
                        </>
                      ) : null}

                      <NavLink to={`/detected-locations/upload-datasets`} id="RouterNavLink">
                        <Button variant="secondary">
                          <BsFillCloudUploadFill></BsFillCloudUploadFill> Upload Datasets
                        </Button>
                      </NavLink>
                    </Col>
                    <Col>
                      <DetectedLocationTable
                        handlePage={handleOnChangePage}
                        handlePerPage={handleOnChangePerPage}
                        perPage={perPage}
                        page={page}
                        orderBy={orderBy}
                        order={order}
                        handleOrder={handleOnChangeOrder}
                        handleOrderBy={handleOnChangeOrderBy}></DetectedLocationTable>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="trend" title="Trend analysis">
                  <TrendAnalysis
                    filterBy={filterBy}
                    setFilterBy={setFilterBy}
                    startDateTrend={startDateTrend}
                    setStartDateTrend={setStartDateTrend}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    startMonth={startMonth}
                    setStartMonth={setStartMonth}
                    endMonth={endMonth}
                    setEndMonth={setEndMonth}
                    startYear={startYear}
                    setStartYear={setStartYear}
                    endYear={endYear}
                    setEndYear={setEndYear}
                    labelDay={labelDay}
                    setLabelDay={setLabelDay}></TrendAnalysis>
                </Tab>
              </Tabs>
            </Container>
          </Col>
          <Col lg={7} className="shadow p-3 mb-5 bg-body rounded">
            <OneMap
              isVisualization={isVisualization}
              dragLocation={dragLocation}
              dragLocationB={dragLocationB}
              setDragLocation={setDragLocation}
              setDragLocationB={setDragLocationB}></OneMap>
          </Col>
        </Row>
        <PdfInformationFormModal onData={handleProcessed} handleFetchDataPdf={handleFetchDataPdf}></PdfInformationFormModal>
      </Container>
    </>
  );
}

export default React.memo(DetectedLocation);
