import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { DownloadPdfParams, GetDetectedLocationDetailRes, GetDetectedLocationParams, GetDetectedLocationRes } from './detectedLocationType';
import { parseBase64ToPdf } from '../../utils/helper';

export const getLatLng = createAsyncThunk<[{ lat: number; lng: number }], { isVisualization: boolean }>(
  'detectedLocation/getLatLng',
  async ({ isVisualization }, { rejectWithValue }) => {
    try {
      if (!isVisualization) return [];
      const { data } = await authInstance.get(`/api/detected-locations/coverage-visualization`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDetectedLocation = createAsyncThunk<GetDetectedLocationRes, GetDetectedLocationParams, { state: RootState }>(
  'detectedLocation/getDetectedLocation',
  async (
    {
      page = 1,
      perPage = 10,
      fromDate,
      toDate,
      isCrackDetected,
      fromTime,
      toTime,
      triggerType,
      latitude,
      longitude,
      radius,
      orderBy,
      order,
      selectFolder,
      isProfile,
      filterBy,
      defectTypes,
      roadName,
      status,
      latitudeB,
      longitudeB,
      distance
    },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        page,
        perPage,
        fromDate,
        toDate,
        isCrackDetected,
        fromTime,
        toTime,
        triggerType,
        latitude,
        longitude,
        radius,
        order,
        orderBy,
        customOrderBy: orderBy == 'datetime' ? 'datetime' : undefined,
        selectFolder,
        isProfile,
        filterBy,
        defectTypes,
        roadName,
        status,
        latitudeB,
        longitudeB,
        distance
      };
      if (isProfile !== undefined) {
        params['isProfile'] = isProfile;
      }
      const { data } = await authInstance.get(`/api/detected-locations`, {
        params: _.pickBy(params, (value) => {
          return typeof value === 'boolean' || Boolean(value);
        })
      });
      if (data.data.length > 0) {
        const ids = data.allData.map((item: any) => item.id);
        localStorage.setItem('detectedLocation', JSON.stringify(ids));
      }
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDetectedLocationDetail = createAsyncThunk<GetDetectedLocationDetailRes, { id: string }, { state: RootState }>(
  'detectedLocation/getDetectedLocationDetail',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/detected-locations/${id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteDetectedLocationDetail = createAsyncThunk<GetDetectedLocationDetailRes, { id: string }, { state: RootState }>(
  'detectedLocation/deleteDetectedLocationDetail',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.delete(`/api/detected-locations/${id}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getDrawRectangleImageById = createAsyncThunk<
  { image: string; imageNone: string; defaultDrawRectangleImage: Array<any>; height: number; width: number },
  { detectedLocationId: string; threshIou?: number },
  { state: RootState }
>('detectedLocation/getDrawRectangleImageById', async ({ detectedLocationId, threshIou = 0.8 }, { rejectWithValue }) => {
  try {
    const { data } = await authInstance.get(`/api/detected-image/drew-pavement-image/${detectedLocationId}`, { params: { threshIou } });
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getPanoImageByDetectedLocationId = createAsyncThunk<{ image: string }, { detectedLocationId: string }, { state: RootState }>(
  'detectedLocation/getPanoImageByDetectedLocationId',
  async ({ detectedLocationId }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/detected-image/pano-image/${detectedLocationId}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generatePanoImageByFolderName = createAsyncThunk<{ image: string }, { id: string }, { state: RootState }>(
  'detectedLocation/generatePanoImageByFolderName',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/s3/panorama-convert`, { id: id });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadReportPdf = createAsyncThunk<{ file: string }, DownloadPdfParams, { state: RootState }>(
  'detectedLocation/downloadReportPdf',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(
        '/api/report-pdf',
        {
          ...payload
        },
        {
          params: { detectedLocationId: payload.detectedLocationId }
        }
      );
      const pdfBlob = parseBase64ToPdf(data.file);
      saveAs(pdfBlob, `${payload.folderName}.pdf`);

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadReportPdfAndZip = createAsyncThunk<{ file: string }, DownloadPdfParams, { state: RootState }>(
  'detectedLocation/downloadReportPdfAndZip',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(
        `/api/report-pdf/generate-pdf-and-zip/${payload.detectedLocationId}`,
        {
          ...payload
        },
        {
          responseType: 'blob'
        }
      );

      const zipBlob = new Blob([response.data], { type: 'application/zip' });
      const fileName = `${payload.folderName}.zip`;
      saveAs(zipBlob, fileName);

      return { file: fileName };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getNumberDefectIds = createAsyncThunk<{ defectType: number }, { id: string }, { state: RootState }>(
  'detectedLocation/getNumberDefectIds',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await authInstance.get(`/api/cracks/number-defects/${id}`);

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const saveAsDraftPdf = createAsyncThunk<{ file: string }, DownloadPdfParams, { state: RootState }>(
  'detectedLocation/saveAsDraftPdf',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(
        '/api/report-pdf/save-as-draft',
        {
          ...payload
        },
        {
          params: { detectedLocationId: payload.detectedLocationId }
        }
      );

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateDetectedData = createAsyncThunk<{ data: boolean }, { id: string; position: any; width: any; height: any }, { state: RootState }>(
  'detectedLocation/updateDetectedData',
  async ({ id, position, width, height }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.put(`/api/detected-image/drew-pavement-image/${id}`, { position, width, height });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllRoadName = createAsyncThunk<string[], void, { state: RootState }>(
  'detectedLocation/getAllRoadName',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/roads/search-road`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createPixelMeasurement = createAsyncThunk<
  number,
  { x1: number; y1: number; x2: number; y2: number; type: number },
  { state: RootState }
>('detectedLocation/createPixelMeasurement', async ({ x1, y1, x2, y2, type }, { rejectWithValue }) => {
  try {
    const { data } = await authInstance.post(`/api/detected-locations/pixel-measurement`, { x1, y1, x2, y2, type });
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getExportPDF = createAsyncThunk<{ url: string }, { key: string }, { state: RootState }>(
  'detectedLocation/getExportPDF',
  async ({ key }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/s3/get-pdf-url?key=${key}`);
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateStatus = createAsyncThunk<boolean, { id: string; status: number }, { state: RootState }>(
  'detectedLocation/updateStatus',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.put(`/api/detected-locations/update-status/${id}`, { status: status });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAllGlacier = createAsyncThunk<number[], void, { state: RootState }>(
  'detectedLocation/getAllGlacier',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/glacier/get-all`);

      return data.map((item: any) => item.year);
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGlacier = createAsyncThunk<number[], { years: number[] }, { state: RootState }>(
  'detectedLocation/updateGlacier',
  async (years, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.put(`/api/detected-locations/update-glacier`, years);

      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generateSummary = createAsyncThunk<{ file: string }, GetDetectedLocationParams, { state: RootState }>(
  'detectedLocation/generateSummary',
  async (
    {
      page = 1,
      perPage = 10,
      fromDate,
      toDate,
      isCrackDetected,
      fromTime,
      toTime,
      triggerType,
      latitude,
      longitude,
      radius,
      orderBy,
      order,
      selectFolder,
      isProfile,
      filterBy,
      defectTypes,
      roadName,
      status,
      latitudeB,
      longitudeB,
      distance
    },
    { rejectWithValue }
  ) => {
    try {
      const params = {
        page,
        perPage,
        fromDate,
        toDate,
        isCrackDetected,
        fromTime,
        toTime,
        triggerType,
        latitude,
        longitude,
        radius,
        order,
        orderBy,
        customOrderBy: orderBy == 'datetime' ? 'datetime' : undefined,
        selectFolder,
        isProfile,
        filterBy,
        defectTypes,
        roadName,
        status,
        latitudeB,
        longitudeB,
        distance
      };
      const response = await authInstance.get(`/api/detected-locations/generate-summary`, {
        params: _.pickBy(params, (value) => {
          return typeof value === 'boolean' || Boolean(value);
        }),
        responseType: 'blob'
      });
      saveAs(response.data, `generate-summary-${new Date().toISOString()}.xlsx`);
      return { file: `generate-summary-${new Date().toISOString()}.xlsx` };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const generateSummarySelected = createAsyncThunk<{ file: string }, { ids: string[] }, { state: RootState }>(
  'detectedLocation/generateSummarySelected',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(
        `/api/detected-locations/generate-summary-selected`,
        { ids },
        {
          responseType: 'blob'
        }
      );

      saveAs(response.data, `generate-summary-${new Date().toISOString()}.xlsx`);
      return { file: `generate-summary-${new Date().toISOString()}.xlsx` };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
