/* eslint-disable prefer-const */
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  selectAllDataDetectedLocation,
  selectDetectedLocationDetail,
  selectDetectedLocationList,
  selectDetectedLocationSearch,
  selectRoute,
  selectLatLng
} from '../detectedLocationSlice';
import {
  TileLayer,
  Marker,
  MapContainer,
  Circle,
  Polyline,
  useMap,
  ScaleControl,
  CircleMarker,
  useMapEvents,
  LayersControl,
  Popup,
  Tooltip
} from 'react-leaflet';
import L, { LatLng, LatLngExpression } from 'leaflet';
import LocationIconBlue from '../../../Assets/images/location-pin-blue.png';
import MakerBlue from '../../../Assets/images/maker_blue.png';
import MakerRed from '../../../Assets/images/maker_red.png';
import LocationPrimaryIcon from '../../../Assets/images/location-pin-primary-color.png';
import React, { useRef, useEffect, useState } from 'react';
import { selectSelectedLocation, selectSelectedLocationB } from '../../onemap/onemapSlice';
import LocationRedIcon from '../../../Assets/images/location-pin-red.png';
import 'leaflet.polylinemeasure';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import { setDetectedLocationDetail } from '../detectedLocationSlice';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'polyline-encoded';

const LocationDetail = new L.Icon({
  iconUrl: LocationPrimaryIcon,
  iconSize: [42, 42],
  iconAnchor: [16, 32]
});
const DefaultIcon = L.icon({
  iconUrl: LocationIconBlue,
  iconSize: [26, 26],
  iconAnchor: [16, 32]
});
const SelectedLocation = new L.Icon({
  iconUrl: LocationRedIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});
const BlueIcon = L.icon({
  iconUrl: LocationIconBlue,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});
const MakerIconRed = L.icon({
  iconUrl: MakerRed,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

const MakerIconBlue = L.icon({
  iconUrl: MakerBlue,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

L.Marker.prototype.options.icon = DefaultIcon;

const DraggableMarker: any = ({ initialPosition, onPositionChange, tooltip, location, icon }: any) => {
  const [position, setPosition] = useState(initialPosition);
  useEffect(() => {
    setPosition(initialPosition);
  }, [initialPosition]);

  const eventHandlers = {
    dragend(event: any) {
      const marker = event.target;
      const newPosition = marker.getLatLng();
      setPosition(newPosition);
      onPositionChange([newPosition.lat, newPosition.lng]);
    }
  };

  return (
    <Marker icon={icon} position={position} draggable={true} eventHandlers={eventHandlers}>
      <Tooltip>
        <div>
          <div>Location {location}</div>
          <div>{tooltip}</div>
        </div>
      </Tooltip>
    </Marker>
  );
};

const OneMap = ({
  isVisualization,
  dragLocation,
  dragLocationB,
  setDragLocation,
  setDragLocationB
}: {
  isVisualization: boolean;
  dragLocation: number[];
  dragLocationB: number[];
  setDragLocation: any;
  setDragLocationB: any;
}) => {
  const dispatch = useAppDispatch();
  const mapRef = useRef<L.Map>(null);
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const listDetectedLocation = useAppSelector(selectDetectedLocationList);
  const allDetectedLocation = useAppSelector(selectAllDataDetectedLocation);
  const route = useAppSelector(selectRoute);
  const listLatLng = useAppSelector(selectLatLng);
  const searchValue = useAppSelector(selectDetectedLocationSearch);
  const selectedLocation = useAppSelector(selectSelectedLocation);
  const selectedLocationB = useAppSelector(selectSelectedLocationB);
  const centerPosition: LatLngExpression = selectedLocation ? [selectedLocation.LATITUDE, selectedLocation.LONGITUDE] : [1.331229601, 103.903206301];
  const [zoom, setZoom] = useState<number>(16);
  const prevDetectedLocationDetailId = useRef(detectedLocationDetail?.id);

  useEffect(() => {
    if (selectedLocation) {
      mapRef.current?.flyTo([selectedLocation.LATITUDE, selectedLocation.LONGITUDE], 18);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (searchValue?.latitude && searchValue.longitude) mapRef.current?.flyTo([searchValue?.latitude, searchValue?.longitude], 18);
  }, [searchValue]);

  const renderCircleCondition = selectedLocation && !selectedLocationB;
  const radiusInMeters: number = searchValue?.radius ? searchValue?.radius : 0;

  const PolylineMeasure = () => {
    const map = useMap();
    useEffect(() => {
      const polylineMeasure = (L.control as any).polylineMeasure({
        position: 'topleft',
        unit: 'kilometres',
        showBearings: true,
        clearMeasurementsOnStop: false,
        showClearControl: true,
        showUnitControl: true
      });
      polylineMeasure.addTo(map);
      return () => {
        polylineMeasure.remove();
      };
    }, []);
    return null;
  };

  const PolylineLayer = ({ encodedRoute }: any) => {
    const map = useMap();

    useEffect(() => {
      if (!encodedRoute) {
        console.error('Encoded route data is not provided');
        return;
      }

      let test = L.polyline((L as any).PolylineUtil.decode(encodedRoute, 5));

      const route = (L as any).Polyline.fromEncoded(encodedRoute, {
        color: 'blue',
        weight: 5,
        opacity: 0.7
      }).addTo(map);

      return () => {
        if (map && route) {
          map.removeLayer(route);
        }
      };
    }, [map, encodedRoute]);

    return null;
  };

  const MapChildComponent = () => {
    const mapEvents = useMapEvents({
      zoomend: () => {
        setZoom(mapEvents.getZoom());
      }
    });
    return null;
  };

  // useEffect(() => {
  //   localStorage.setItem('zoom', String(zoom));
  //   // if (zoom < 25) {
  //   //   dispatch(setDetectedLocationDetail(null));
  //   // }
  // }, [zoom]);

  const AddMarkersToMap = () => {
    const map = useMap();
    map.setMaxZoom(25);

    useEffect(() => {
      if (allDetectedLocation) {
        let markerClusterGroup: any;
        let markers: any[] = [];
        let detectedLocationMarker: any;

        if (allDetectedLocation.length > 500) {
          markerClusterGroup = L.markerClusterGroup({
            disableClusteringAtZoom: 25
          });
        }

        allDetectedLocation.forEach((location) => {
          const marker = L.marker([location.latitude, location.longitude], {
            icon: location.id == detectedLocationDetail?.id ? LocationDetail : DefaultIcon
          });

          marker.on('click', () => {
            dispatch(setDetectedLocationDetail(location));
          });

          if (location.id == detectedLocationDetail?.id) {
            detectedLocationMarker = marker;
          } else if (markerClusterGroup) {
            markerClusterGroup.addLayer(marker);
          } else {
            markers.push(marker);
          }
        });

        if (markerClusterGroup) {
          map.addLayer(markerClusterGroup);
        } else {
          markers.forEach((marker) => {
            map.addLayer(marker);
          });
        }

        if (detectedLocationMarker) {
          map.addLayer(detectedLocationMarker);
        }

        return () => {
          if (markerClusterGroup) {
            map.removeLayer(markerClusterGroup);
          } else {
            markers.forEach((marker) => map.removeLayer(marker));
          }

          if (detectedLocationMarker) {
            map.removeLayer(detectedLocationMarker);
          }
        };
      }
    }, [allDetectedLocation, detectedLocationDetail, map]);

    useEffect(() => {
      if (prevDetectedLocationDetailId.current === detectedLocationDetail?.id) {
        return;
      }
      if (allDetectedLocation && detectedLocationDetail && allDetectedLocation.length > 500) {
        const zoomOutLevel = 13;
        const zoomInLevel = 18;
        const duration = 2;
        // map.flyTo(map.getCenter(), zoomOutLevel, { duration: duration });
        // localStorage.setItem('zoom', String(zoomOutLevel));
        prevDetectedLocationDetailId.current = detectedLocationDetail?.id;
      }
    }, [detectedLocationDetail?.id]);

    return null;
  };

  const mapMarkers = listLatLng?.map((latLng, i) => <CircleMarker key={i} center={latLng} radius={4} color="green" />);
  return (
    <div className="d-flex position-relative z-index-0 h-100 flex-column min-height-sm-300">
      <div className="h-100">
        <MapContainer center={centerPosition} zoom={zoom} scrollWheelZoom={true} ref={mapRef} className="w-100 h-100">
          <AddMarkersToMap />
          <MapChildComponent />

          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Default">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Origin">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Original/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Grey">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Grey/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Night">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Night/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
                detectRetina={true}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite">
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Satellite/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          <ScaleControl position="bottomleft" maxWidth={240} metric={true} imperial={false} />
          {selectedLocation && !selectedLocationB && radiusInMeters && (
            <Marker icon={SelectedLocation} position={[selectedLocation.LATITUDE, selectedLocation.LONGITUDE]}></Marker>
          )}

          {renderCircleCondition && <Circle center={[selectedLocation.LATITUDE, selectedLocation.LONGITUDE]} radius={radiusInMeters} />}

          {listLatLng && listLatLng.length && (
            <>
              <Polyline positions={listLatLng} pathOptions={{ color: '#565e6b' }} opacity={0.5} />
              {mapMarkers}
            </>
          )}
          {isVisualization && detectedLocationDetail && (
            <Marker icon={SelectedLocation} position={[detectedLocationDetail.latitude, detectedLocationDetail.longitude]}></Marker>
          )}
          <PolylineMeasure />
          {dragLocation && dragLocation.length && route && (
            <DraggableMarker
              initialPosition={dragLocation}
              onPositionChange={setDragLocation}
              tooltip={route.route_summary?.start_point}
              location={'A'}
              icon={MakerIconBlue}
            />
          )}
          {dragLocationB && dragLocationB.length && route && (
            <DraggableMarker
              initialPosition={dragLocationB}
              onPositionChange={setDragLocationB}
              tooltip={route.route_summary?.end_point}
              location={'B'}
              icon={MakerIconRed}
            />
          )}
          {route && <PolylineLayer encodedRoute={route.route_geometry} />}
        </MapContainer>
      </div>
    </div>
  );
};

export default React.memo(OneMap);
